import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@ruf/idp-auth';
import { BasicGuard } from './basic.guard';
import { GlossaryComponent } from './shared/glossary/glossary.component';
import { ProfilePageComponent } from './modules/profile-page/profile-page.component';
import { CustomNotificationComponent } from 'src/app/modules/custom-notification/custom-notification';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'authentication',
    pathMatch: 'full'
  },
  {
    path: 'authentication',
     canActivate: [AuthGuard],
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'notification-search',
    canActivate: [BasicGuard],
    loadChildren: () => import('./modules/notification/notification-search.module').then(m => m.NotificationSearchModule)
  },
  {
    path: 'subscriber',
    canActivate: [BasicGuard],
    loadChildren: () => import('./modules/subscriber/subscriber.module').then(m => m.SubscriberModule)
  },
  
  {
    path: 'help',
    canActivate: [BasicGuard],
    component: GlossaryComponent
  },
  {
    path: 'dashboard',
    canActivate: [BasicGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'profile',
    canActivate: [BasicGuard],
    component: ProfilePageComponent
  },
    {
        path:'**', redirectTo: 'authentication'
    },
  {
    path: 'noaccess',
    component: CustomNotificationComponent
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
