import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http';

import { ENDPOINTS } from '@app/endpoints';
import { ApiService } from './api.service';
import { SharedService } from '.';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends ApiService {

    private _selectedDeal;
    private navigateBacktoResultswithRetry: boolean;
    private subscriberInClaims: any = SharedService.subscriberInClaims;
    private navigateBacktoResultswithReplay: boolean;

    public clearSessionStorage(): void {
        this._selectedDeal = undefined;
        localStorage.clear();
    }

    public setNavigateBacktoResultswithRetry(navigate: any): void {
        this.navigateBacktoResultswithRetry = navigate;
        localStorage.setItem('navigateBacktoResultswithRetry', navigate);
    }
    public getNavigateBacktoResultswithRetry(): any {
        if (!this.navigateBacktoResultswithRetry) {
            return localStorage.getItem('navigateBacktoResultswithRetry');
        }

        return this.navigateBacktoResultswithRetry;
    }
    public setNavigateBacktoResultswithReplay(navigate: any): void {
        this.navigateBacktoResultswithReplay = navigate;
        localStorage.setItem('navigateBacktoResultswithReplay', navigate);
    }
    public getNavigateBacktoResultswithReplay(): any {
        if (!this.navigateBacktoResultswithReplay) {
            return localStorage.getItem('navigateBacktoResultswithReplay');
        }

        return this.navigateBacktoResultswithReplay;
    }
    public setSelectedDeal(deal: any): void {
        this._selectedDeal = deal;
        localStorage.setItem('deal', JSON.stringify(deal));
    }
    public getSelectedDeal(): any {
        if (!this._selectedDeal) {
            return JSON.parse(localStorage.getItem('deal'));
        }

        return this._selectedDeal;
    }

    public search(search: any): Observable<any> {
        if(search.version === 'v2') {
            const requestBody = this.mapSubscriberSearchRequest(search);
            return this.postSearch(ENDPOINTS.AWS_NOTIFICATION_SEARCH, requestBody).pipe(
                map( response => this.mapSubscriberSearchResponse(response)
            ));
        } else {
            return this.post(ENDPOINTS.NOTIFICATION_SEARCH, search, SharedService.subscriberInClaims);
        }
    }

    public gateway(params: any = ''): Observable<any> {
        return this.get(ENDPOINTS.GATEWAY_API, params);
    }


    public gatewaySynchronus(params: any = ''): Promise<any> {
        return this.getSynchronous(ENDPOINTS.GATEWAY_API, params);
    }

    public payload(id: any, version: any): Observable<any> {

        if(version === 'v2') {

            return this.get(ENDPOINTS.AWS_NOTIFICATION_RETRY + '/' + id + '/full-entity?includeSuccessorsInHistory=true');

        } else {

            const params = new HttpParams()
                .set('version', version);
            const url = ENDPOINTS.NOTIFICATION_PAYLOAD + '/' + id + "/snapshot";
            return this.get(url, params);
            
        }
    }

    public retryNotification(note: any): Observable<any> {
        
        const notificationBody = { subscriberCode: note.subscriberId, eventType: note.eventType, version: note.version };
        if (note.version === 'v2') {
            return this.patchForRoutingAPI(ENDPOINTS.AWS_NOTIFICATION_RETRY + '/' + note.notificationId + '/retry', '');
        } else if (note.version === 'v1') {
            return this.patch(ENDPOINTS.NOTIFICATION_RETRY + '/' + note.notificationId, notificationBody, SharedService.subscriberInClaims);
        }
    }

    public replayNotification(note: any): Observable<any> {
        return this.patchForRoutingAPI(ENDPOINTS.AWS_NOTIFICATION_RETRY + '/' + note.notificationId + '/replay', '');
    }
    
    public getEventsList(params: any = '', showActiveSubscribers?: boolean): Observable<any> {
        if(params === 'v2') {
            const param = new HttpParams().set('hasActiveSubscribers', showActiveSubscribers);
            return this.get(ENDPOINTS.AWS_WEBHOOK_EVENT_TYPES, param).pipe(
                map(res => this.mapEventsDataFields(res)));
        }
        const param = new HttpParams().set('source', 'DB2')
            .set('subscribersInClaim', SharedService.subscriberInClaims);
        return this.get(ENDPOINTS.GET_EVENTTYPES, param);
    }

    public getSubcriberList(params: any = ''): Observable<any> {
        if(params === 'v2') {
            const param = new HttpParams().set("pageSize", 1000);
            return this.get(ENDPOINTS.AWS_SUBSCRIBER_API, param).pipe(
                map(res => this.mapSubscriberDataFields(res)));
        } else {
            const param = new HttpParams().set('version', params)
                .set('subscribersInClaim', SharedService.subscriberInClaims);
            return this.get(ENDPOINTS.GET_SUBSCRIBERS, param);
        }
    }

    public getContactType(params: any = ''): Observable<any> {
        return this.get(ENDPOINTS.GET_CONTACTTYPE);
    }

    public addContact(contact: any): Observable<any> {
        return this.post(ENDPOINTS.POST_CONTACT, contact, '');
    }

    public getContactList(params: any = ''): Observable<any> {
        const param = new HttpParams().set('contactName', 'Pay')
            .set('subscribersInClaim', SharedService.subscriberInClaims);
        return this.get(ENDPOINTS.POST_CONTACT, param);
    }

    public getEventSubscriberList(val: any = '', params: any = '', version?: string): Observable<any> {
        if(version === 'v2') {
            const param = new HttpParams().set("eventTypes", val).set("pageSize", 1000);
            let eventSubscriberList = this.get(ENDPOINTS.AWS_SUBSCRIBER_API, param).pipe(
                map(res => this.mapSubscriberDataFields(res)))
                .pipe(map(res => res.subscriberList));
            const eventParam = new HttpParams().set('hasActiveSubscribers', true);
            let eventCallList = this.get(ENDPOINTS.AWS_WEBHOOK_EVENT_TYPES, eventParam).pipe(
                map(res => this.mapEventsDataCallFields(res, val)));
            return merge(eventSubscriberList, eventCallList);
        } else {
            const param = new HttpParams().set(params, val)
                .set('subscribersInClaim', SharedService.subscriberInClaims);
            return this.get(ENDPOINTS.GET_EVENTS, param);
        }
    }
    public getSubscriberListByEvents(val: any = '', params: any = '', version?: string): Observable<any> {
        if(version === 'v2') {
            const param = new HttpParams().set("eventTypes", val).set("pageSize", 1000);
            return this.get(ENDPOINTS.AWS_SUBSCRIBER_API, param).pipe(
                map(res => this.mapSubscriberDataFields(res)))
                .pipe(map(res => res.subscriberList));
        } else {
            const param = new HttpParams().set(params, val)
                .set('subscribersInClaim', SharedService.subscriberInClaims);
            return this.get(ENDPOINTS.GET_EVENTS, param);
        }
    }
    //US1002945 start
    public getEventsCompleteList(params: any = ''): Observable<any> {
        const param = new HttpParams().set('source', params === 'v1' ? 'DB2' : 'EAPI').set('completeList', 'true')
            .set('subscribersInClaim', SharedService.subscriberInClaims);
        return this.get(ENDPOINTS.GET_EVENTTYPES, param);
    }
    //US1002945 End

    public getAllEventsList(showActiveSubscribers?: boolean): Observable<any>{
        const param = new HttpParams().set('hasActiveSubscribers', showActiveSubscribers);
        return this.get(ENDPOINTS.AWS_WEBHOOK_EVENT_TYPES, param).pipe(
            map(res => this.mapEventsDataFields(res)));
        }

    //Mapping fields for new A4E v2 response, so that it matches with v1 response, wherever possible
    private mapEventsDataCallFields(response: any, inputEventTypes?: any) {
        return response
        .filter(item => item.type.includes(inputEventTypes.slice(0, -1)))
        .map(item => {
            return {
                eventTypeName: item.type,
                eventType: item.code,
                db2EventProductName: item.productName,
                searchKeys: item.searchKeys
            };
        });
    }

    private mapEventsDataFields(response: any) {
        const eventTypesList = response.map( item => {
            return {
                eventTypeName: item.type,
                eventType: item.code,
                db2EventProductName: item.productName,
                searchKeys: item.searchKeys,
                ...item
            };
        });

        return {
            eventTypesList
        }
    }

    private mapSubscriberDataFields(response: any) {
        const subscriberCodeInClaim = SharedService.subscriberInClaims.split(',').map(id => id.trim());
        const pageNumber = response.pageNumber;
        const pageSize = response.pageSize;
        const totalRowCount = response.totalRowCount;
        if(SharedService.subscriberInClaims && SharedService.subscriberInClaims.trim() !== '') {
            const subscriberList = response.subscribers
            .filter(item => subscriberCodeInClaim.includes(item.lookupCode.toString()))
            .map(item => {
                    return{
                        ...item,
                        subscriberName: item.name,
                        subscriberCode: item.lookupCode,
                        subscriberStatus: item.status,
                        updatedDate: item.updatedDateTime,
                        insertedBy: item.createdBy,
                    };
                });

            return {
                pageNumber,
                pageSize,
                totalRowCount,
                totalReturnedCount : subscriberList.length,
                subscriberList
            }
        } else {
            const subscriberList = response.subscribers
            .map(item => {
                    return{
                        ...item,
                        subscriberName: item.name,
                        subscriberCode: item.lookupCode,
                        subscriberStatus: item.status,
                        updatedDate: item.updatedDateTime,
                        insertedBy: item.createdBy,
                    };
                });

            return {
                pageNumber,
                pageSize,
                totalRowCount,
                totalReturnedCount : subscriberList.length,
                subscriberList
            }
        }
    }

    private mapSubscriberSearchRequest(item: any) {
        let statusList = [];
        if(item.status !=null && item.status !="undefined" && item.status.length > 0){
        item.status.forEach(element => {
            if(element === 'On Hold'){
                statusList.push('UNSENT'); 
            }else{
            statusList.push(element.toUpperCase());
            }
            });
        }
            const sortResultsBy = {
                 fieldName: this.mapFieldName(item.sortResultsBy[0].fieldName),
                 ordering: item.sortResultsBy[0].ordering.toUpperCase()
            }

            let pagination = {
                    pageNumber: 0,
                    pageSize: 1000
            }

            if(item.pagination) {
                pagination = {
                    pageNumber: Math.floor(item.pagination.offset/item.pagination.limit),
                    pageSize: item.pagination.limit
                }
            }

            const modifiedBody = {
                pagination,
                sortResultsBy,
                fromDate: item.fromDate,
                toDate: item.toDate,
                eventTypes: item.eventType,
                statuses: statusList,
                notificationId: item.notificationId,
                subscribers: item.subscriberId,
                leaseId: item.leaseId,
                productTypeCode: item.productCode,
                terminalId: item.terminalId,
                boardingSourceId: item.boardingSourceId,
                externalReferenceId: item.externalReferenceId,
                dealId: item.dealId,
                caseId: item.caseId,
                terminalOrderId: item.orderId,
                disputeCaseNumber: item.disputeCaseNumber,
                disputeOccurNumber: item.disputeOccurNumber,
                ficoId: item.ficoId,
                chainCode: item.chainCode,
                settlementBatchNumber: item.batchNumber,
                settlementProcessDate: item.processDate,
                settlementRejectionReason: item.rejectionRsnCode,
                clientTrackingId: item.clientTrackingId,
                dbaName: item.dbaName2,
                fundingMethod: item.fundingMethod,
                effectiveDate: item.effectiveDate,
                ddaNumber: item.ddaNumber,
                cardNumber: item.cardNumber,
                startDate: item.startDate,
                source: item.source,
                holdStatus: item.holdStatus,
                leadId: item.leadId,
                leadStatus: item.leadStatus,
                referralPartnerCode: item.referralPartnerCode,
                varsheetId: item.varsheetId,
                giftCardOrderId: item.giftCardOrderId,
                boardingOrderId: item.boardingOrderId,
                quoteId: item.quoteId,
                partnerId: item.partnerId,
                accountId: item.accountId,
                legalName: item.legalName,
                taxId: item.taxId,
                vendorName: item.vendorName?.trim(),
                productName: item.productName?.trim(),
                merchantId: null,
                terminalCaseId: null
            }

            if(item.merchantId) {
                modifiedBody.merchantId = item.merchantId[0]
            }

            if(item.eventType != null &&
                (item.eventType == "merchant.equipmentorder.created"||
                    item.eventType == "merchant.equipmentorder.status.updated")) {
                modifiedBody.terminalCaseId = item.caseId;
                modifiedBody.caseId = null;
            }

            return modifiedBody;
    }

    private mapFieldName(fieldName: string) {

        switch(fieldName) {
            case 'notficationId' : return 'NOTIFICATION_ID';
            case 'status' : return 'STATUS';
            case 'eventType' : return 'EVENT_TYPE';
            case 'insertTimeStamp' : return 'INSERT_TIME_STAMP';
            case 'updatedTimeStamp' : return 'UPDATED_TIME_STAMP';
            case 'responseCode' : return 'RESPONSE_CODE';
            default : return '';
        }
    }

    private mapSubscriberSearchResponse(response: any) {
        return {
                    paginationResponse : {
                        totalRowCount: response.totalRowCount,
                        totalReturnedCount: response.totalReturnedCount
                    },
                    data: {
                        notifications: response.notifications.map(item => ({
                            notificationId: item.id,
                            status: item.status,
                            eventTypeName: item.eventType,
                            insertTimeStamp: item.createdDateTime,
                            updatedTimeStamp: item.updatedDateTime,
                            responseCode: item.responseMessage.code,
                            responseMessage: item.responseMessage.message,
                            subscriberId: item.subscriberLookupCode,
                            subscriberName: item.subscriberName,
                            merchantId: item.merchantId,
                            dbaName: item.dbaName,
                            terminalId: item.terminalId,
                            terminalNumber: item.terminalNumber,
                            retried: item.retried,
                            leaseId: item.terminalLeaseId,
                            terminalDescription: item.terminalType,
                            version: "v2"
                        }))
                    }
                };
    }

}


